<template>
  <div id="about-data">
    <!-- Logo -->
    <div class="vx-row w-full">
      <div class="md:w-1/5 w-full"></div>
      <div class="md:w-3/5 w-full">
        <vx-card>
          <div>
            <div class="vx-logo flex justify-center items-center">

              <img :src="getlogo()" class="filcurrent text-primary"
                   style="width: 250px;"/>
            </div>
            <!-- /Logo -->
            <h1 class="py-4">{{ getOrgName() }}</h1>

            <template >
              <div class="vs-row flex py-4 justify-center" v-if="activeUserInfo.account_role >= 2">

                <p class="px-3 dark-theme-text">{{ $t('ShowPrintPopUp') }}</p>
                <vs-checkbox v-model="showPrintPopUp" @change="onPrintChanged"></vs-checkbox>

              </div>
              <div class="vs-row flex py-4 justify-center" v-if="activeUserInfo.account_role >= 2">

                <p class="px-3 dark-theme-text">{{ $t('MergeReceipts') }}</p>
                <vs-checkbox v-model="mergeReceipts" @change="onMergeChanged"></vs-checkbox>
              </div>
              <div class="vs-row flex py-4 justify-center">

                <p class="px-3 dark-theme-text">{{ $t('PinDownload') }}</p>
                <vs-checkbox v-model="pinDownload" @change="onPinDownloadChanged"></vs-checkbox>
              </div>
            </template>


            <div class="">

              <div class="vx-row dark-theme-text" v-if="activeUserInfo">
                <div class="vx-col md:w-1/3">
                  <div class="mt-4">
                    <p class="customer-text-heading">{{$t('CustomerId')}}</p>
                    <p class="customer-text dark-theme-text">{{activeUserInfo.customer_id}}</p>
                  </div>
                  <div class="mt-4">
                    <p class="customer-text-heading">{{$t('FirstName')}}</p>
                    <p class="customer-text dark-theme-text">{{activeUserInfo.first_name}}</p>
                  </div>
                  <div class="mt-4">
                    <p class="customer-text-heading">{{$t('LastName')}}</p>
                    <p class="customer-text dark-theme-text">{{activeUserInfo.last_name}}</p>
                  </div>
                  <div class="mt-4">
                    <p class="customer-text-heading">{{$t('Organisation')}}</p>
                    <p class="customer-text dark-theme-text">{{activeUserInfo.organisation_name}}</p>
                  </div>
                </div>
                <div class="vx-col md:w-1/3">
                  <div class="mt-4">
                    <p class="customer-text-heading">{{$t('Email')}}</p>
                    <p class="customer-text dark-theme-text">{{activeUserInfo.email}}</p>
                  </div>
                  <div class="mt-4">
                    <p class="customer-text-heading">{{$t('Mobile')}}</p>
                    <p class="customer-text dark-theme-text">{{activeUserInfo.mobile}}</p>
                  </div>
                  <div class="mt-4">
                    <p class="customer-text-heading">{{$t('AlternativeMobiles')}}</p>
                    <p class="customer-text dark-theme-text" v-if="activeUserInfo.mobile_1">{{activeUserInfo.mobile_1}}</p>
                    <p class="customer-text dark-theme-text" v-if="activeUserInfo.mobile_2">{{activeUserInfo.mobile_2}}</p>
                  </div>
                  <div class="mt-4">
                    <p class="customer-text-heading">{{$t('Landline')}}</p>
                    <p class="customer-text dark-theme-text">{{activeUserInfo.landline}}</p>
                  </div>
                </div>
                <div class="vx-col md:w-1/3">
                  <div class="mt-4">
                    <p class="customer-text-heading">{{$t('Address')}}</p>
                    <p class="customer-text dark-theme-text">{{ $localStorage.get("address").replace("null", "-") }}</p>
                  </div>
                  <div class="mt-4">
                    <p class="customer-text-heading">{{$t('PostalCode')}}</p>
                    <p class="customer-text dark-theme-text">{{activeUserInfo.postal_code}}</p>
                  </div>
                  <div class="mt-4">
                    <p class="customer-text-heading">{{$t('City')}}</p>
                    <p class="customer-text dark-theme-text">{{activeUserInfo.city}}</p>
                  </div>
                  <div class="mt-4">
                    <p class="customer-text-heading">{{$t('STNR')}}</p>
                    <p class="customer-text dark-theme-text">{{activeUserInfo.stnr}}</p>
                  </div>
                  <div class="mt-4">
                    <p class="customer-text-heading">{{$t('ustldnr')}}</p>
                    <p class="customer-text dark-theme-text">{{activeUserInfo.ustldnr}}</p>
                  </div>
                </div>
                <div class="vx-col md:w-1/3">

                </div>
                <div class="vx-col md:w-1/3">

                </div>
              </div>
            </div>
            <div class="mt-6">
              <a
                target="_blank"
                href="https://drive.google.com/file/d/1ByjyPmacqf9qaXR-ivWKgqCqLRh-sES8/view?usp=sharing"
                style="color: white !important;"
                class="px-4 py-3 text-white bg-primary rounded-lg cursor-pointer">{{ $t('Download TeamViewer') }}</a>
            </div>
            <div class="mt-6">
              <a
                target="_blank"
                href="http://www.hoinprinter.com/upload/POS-58-Series.exe"
                style="color: white !important;"
                class="px-4 py-3 text-white bg-primary rounded-lg cursor-pointer">{{ $t('POSDeviceAPP') }}</a>
            </div>
            <div class="mt-6">
              <vs-button @click="showChangePassword = true" color="danger" type="filled" >{{ $t('ChangePassword') }}
              </vs-button>
            </div>

            <div class="mt-6" v-if="activeUserInfo.account_role >= 2">
              <vs-button @click="showShopTimings = true" color="primary" type="filled" >{{ $t('UpdateShopTimings') }}
              </vs-button>
            </div>

            <div class="my-2 mx-6 dark-theme-text" v-if="currentData && currentData.day">
              <div slot="no-body" class=" p-6">
                <h2 class="font-bold py-4 text-xl">{{ $t('ShopTimings') }}</h2>
                <p>
                  <span class="font-bold">{{ currentData.day }} </span> {{ currentData.start_at }} - {{ currentData.end_at }}
                  <span v-if="currentData.l_start_at">,
                  <span class="font-bold">{{ $t('Lunch') }} </span> {{ currentData.l_start_at }} - {{ currentData.l_end_at }}
                </span>
                </p>
              </div>
            </div>

            <div class="my-6 mx-6" v-if="activeUserInfo && activeUserInfo.parent_contact_details">
              <div slot="no-body" class=" p-6">
                <h2>{{ $t('Contact') }}</h2>
                <div class="py-4 flex justify-center">

                  <div class="flex items-end leading-none pr-4 py-1" v-if="activeUserInfo.parent_contact_details.phone">
                    <feather-icon icon="PhoneCallIcon" svgClasses="h-5 w-5" class="mr-4"/>
                    <a :href="'tel:' + activeUserInfo.parent_contact_details.phone"><span class="mt-1">{{
                        activeUserInfo.parent_contact_details.phone
                      }}</span></a>
                  </div>
                  <div class="flex items-end leading-none pt-4 pr-4 pb-1" v-if="activeUserInfo.parent_contact_details.whatsapp">
                    <font-awesome-icon :icon="['fab', 'whatsapp']" size="lg" class="mr-4"/>
                    <!--              <feather-icon icon="PhoneCallIcon" svgClasses="h-5 w-5" class="mr-4" />-->
                    <a :href="'https://wa.me/' + activeUserInfo.parent_contact_details.whatsapp.replace(' ', '')"
                       target="_blank">
                      <span class="mt-1">{{ activeUserInfo.parent_contact_details.whatsapp }}</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </vx-card>
      </div>
      <div class="md:w-1/5 w-full"></div>
    </div>

    <vx-modal v-if="showShopTimings" @close="showShopTimings = false" component-class="modal-container-fit">
      <template slot="header"></template>
      <template slot="body" >
        <user-update-shop-timings @close="showShopTimings = false"></user-update-shop-timings>
      </template>
    </vx-modal>

    <vx-modal v-if="showChangePassword" @close="showChangePassword = false" component-class="modal-container-fit">
      <template slot="header"></template>
      <template slot="body" >
        <div class="flex flex-centered">
          <div class="vx-col" style="text-align: start;">
            <h3  style="text-align: center;"><strong>Change Password</strong></h3>
            <div  class="w-full py-4 vs-row">
              <vs-input
                autofocus class="w-full py-"
                size="large" type="password" v-validate="'required'"
                validate-on="change"
                @input="changePassCheck"
                :label-placeholder="$t('Password')" v-model="newPassword"/>

              <div class="vs-col">
                <p class="text-sm pass-check-item" :class="[passStrengthValue.caps ? 'text-success' : 'text-gray']">{{ $t('UppercaseLetter') }}</p>
                <p class="text-sm pass-check-item" :class="[passStrengthValue.small ? 'text-success' : 'text-gray']">{{ $t('LowercaseLetter') }}</p>
                <p class="text-sm pass-check-item" :class="[passStrengthValue.numbers ? 'text-success' : 'text-gray']">{{ $t('Number') }}</p>
                <p class="text-sm pass-check-item" :class="[passStrengthValue.special ? 'text-success' : 'text-gray']">{{ $t('OneSymbol') }}</p>
                <p class="text-sm pass-check-item" :class="[passStrengthValue.length ? 'text-success' : 'text-gray']">{{ $t('EightCharacters') }}</p>
              </div>

            </div>
            <div class="vs-row">
              <vs-input class="w-full py-4" size="large" type="password" v-validate="'required'" validate-on="change" :label-placeholder="$t('ConfirmPassword')" v-model="newConfirmPassword"/>

            </div>
            <div class="vs-row flex justify-center mt-3" >
              <vs-button class="ml-2 mt-3" color="primary" @click="changePassword()">{{ $t('ChangePassword') }}</vs-button>
              <vs-button class="ml-4 mt-3" color="danger" @click="showChangePassword = false">{{ $t('Close') }}</vs-button>
            </div>
          </div>

        </div>
      </template>
      <template slot="footer"></template>
    </vx-modal>
  </div>
</template>

<script>
import Logo from '@/layouts/components/Logo'
import VxModal from "@/layouts/components/custom/VxModal";
import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue'
import UserUpdateShopTimings from "@/views/components/UserUpdateShopTimings";

export default {
  name: 'AboutPage',
  components: {
    UserUpdateShopTimings,
    VxModal,
    Logo,
    VueTimepicker,
  },
  data() {
    return {
      showShopTimings: false,
      newPassword: '',
      newConfirmPassword: '',
      showChangePassword: false,
      showPrintPopUp: false,
      mergeReceipts: false,
      pinDownload: false,
      homeData: null,
      passStrengthValue: {
        'caps': false,
        'length': false,
        'special': false,
        'numbers': false,
        'small': false,
      },
      currentData: {},
      shopTimings: [],
      days: [
        "Montag",
        "Dienstag",
        "Mittwoch",
        "Donnerstag",
        "Freitag",
        "Samstag",
        "Sonntag"
      ]
    }
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser
    },
  },
  mounted() {
    try {
      this.homeData = JSON.parse(localStorage.getItem('home'))
    } catch (e) {

    }
    this.updateTimings()
    if (localStorage.getItem('show_print_pop') == null) {
      localStorage.setItem('show_print_pop', 'false')
    }
    if (localStorage.getItem('merge_receipts') == null) {
      localStorage.setItem('merge_receipts', 'false')
    }
    this.showPrintPopUp = this.stringToBoolean(localStorage.getItem('show_print_pop'))
    this.mergeReceipts = this.stringToBoolean(localStorage.getItem('merge_receipts'))
    this.pinDownload = this.stringToBoolean(localStorage.getItem('pin_download'))
  },
  methods: {
    updateTimings() {
      try {
        this.today = this.days[(new Date()).getDay() - 1]
        try {
          this.shopTimings = JSON.parse(this.activeUserInfo.shop_open_timings)
        } catch (e) {
          this.shopTimings = this.activeUserInfo.shop_open_timings
        }

        for(let i = 0; i < this.shopTimings.length; i++) {

          if (this.shopTimings[i].day == this.today) {
            this.currentData = this.shopTimings[i];
            break
          }
        }
      } catch (e) {

      }
    },
    updateSettings() {
      let payload = {
        'show_print_popup': this.showPrintPopUp === true ? 1 : 0,
        'merge_receipts': this.mergeReceipts === true ? 1 : 0,
        'download_csv': this.pinDownload === true ? 1 : 0
      }
      this.$vs.loading()

      this.$store.dispatch('auth/updateUserSettings', payload).then((data) => {
        this.$vs.loading.close()
        this.$vs.notify({
          position: 'top-center',
          title: 'Success',
          text: data.message,
          iconPack: 'feather',
          icon: 'icon-check-circle',
          color: 'success',
        })
      })
      .catch((err) => {
        this.$vs.loading.close()
        this.$vs.notify({
          position: 'top-center',
          title: 'Error',
          text: error.response.data.message || error.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        })
      })
    },
    getOrgName() {
      try {
        return JSON.parse(localStorage.getItem('home')).organisation_name
      } catch (e) {
        return ''
      }
    },
    stringToBoolean(string) {
      switch (string.toLowerCase().trim()) {
        case 'true':
        case 'yes':
        case '1':
          return true
        case 'false':
        case 'no':
        case '0':
        case null:
          return false
        default:
          return Boolean(string)
      }
    },
    onPrintChanged(value) {
      localStorage.setItem('show_print_pop', this.showPrintPopUp)
      this.updateSettings()
    },
    onMergeChanged(value) {
      localStorage.setItem('merge_receipts', this.mergeReceipts)
      this.updateSettings()

    },
    onPinDownloadChanged(value) {
      localStorage.setItem('pin_download', this.pinDownload)
      this.updateSettings()

    },

    getlogo() {
      let j = JSON.parse(localStorage.getItem('home'))
      if(j.logo) {
        return 'https://pjtelesoftgmbh.de' + j.logo
      } else {

        return 'https://pjtelesoftgmbh.de' + j.login_page_logo
      }
    },
    changePassword() {
      if (!(this.passStrengthValue.caps && this.passStrengthValue.length
        && this.passStrengthValue.special && this.passStrengthValue.numbers
        && this.passStrengthValue.small)) {
        this.$vs.notify({
          position: 'top-center',
          title: 'Error',
          text: 'Password is not valid',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        })
        return
      }
      if (this.newPassword !== this.newConfirmPassword) {
        this.$vs.notify({
          position: 'top-center',
          title: 'Error',
          text: 'Password not matched',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        })
        return
      }
      this.$vs.loading()
      return this.$store.dispatch('customer/updatePassword',
        { password: this.newPassword })
        .then((data) => {
          this.$vs.loading.close()
          if (data.message_type == 1) {
            this.$vs.notify({
              position: 'top-center',
              title: 'Success',
              text: 'Password updated',
              iconPack: 'feather',
              icon: 'icon-check-circle',
              color: 'success',
            })
            this.newConfirmPassword = ''
            this.newPassword = ''
            this.showChangePassword = false
          } else {
            this.$vs.notify({
              position: 'top-center',
              title: 'Error',
              text: data.message,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger',
            })
          }
        })
        .catch((error) => {
          console.error(error)
          this.$vs.loading.close()
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: error.response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
    changePassCheck(value) {
      let caps = false
      let numbers = false
      let small = false
      let special = false
      for (let index = 0; index < this.newPassword.length; index++) {
        const char = this.newPassword.charCodeAt(index)
        if (char >= 65 && char <= 90) {
          caps = true
        } else if (char >= 48 && char <= 57) {
          numbers = true
        } else if (char >= 97 && char <= 122) {
          small = true
        } else if (char >= 48 && char <= 57) {
          numbers = true
        } else if ([33, 35, 36, 37, 38, 64, 42, 63, "33", "35", "36", "37", "38", "64", "42", "63"].includes(char)) {
          special = true
        }
      }
      this.passStrengthValue.caps = caps
      this.passStrengthValue.numbers = numbers
      this.passStrengthValue.small = small
      this.passStrengthValue.special = special
      if (this.newPassword.length < 8) {
        this.passStrengthValue.length = false
      } else {
        this.passStrengthValue.length = true
      }
      const obj = { ...this.passStrengthValue }
      this.passStrengthValue = {}
      this.passStrengthValue = { ...obj }
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
#about-data {
  -webkit-font-smoothing: antialiased;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;

  .vx-card__title {
    font-size: 20px;
    font-weight: bold;
  }
  h1 {
    font-size: 20px;
    font-weight: 500;
  }

  //h3 {
  //  margin: 40px 0 0;
  //}

  ul {
    list-style-type: none;
    padding: 16px;
  }

  li {
    display: inline-block;
    margin: 0 10px;
  }

  .mobile {
    font-weight: bold;
  }

  a {
    color: #42b983;
  }
  .support-timings-table {
    border: none;
    tr {
      td {
        padding: 10px 8px;
      }
    }
  }
  .customer-text-heading {
    text-align: start;
    font-weight: bold;
    opacity: .6;
  }
  .customer-text {
    text-align: start;
    font-size: 18px;
  }
}

</style>
